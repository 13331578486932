import React from "react";
import clsx from "clsx";
import { useMainHeight } from "@hooks/useMainHeight";

function MainLayout({
  children,
  type = "normal",
  addRelativeClass,
}: {
  children: React.ReactNode;
  type?: "filling" | "normal" | "fillingScroll" | "fixedWitdhScroll";
  addRelativeClass?: boolean;
}) {
  const mainHeight = useMainHeight();
  return (
    // Set max width and paddings for all content on the pages in the protected area
    <div
      className={clsx(
        addRelativeClass && "relative",
        type === "normal"
          ? "mx-auto max-w-7xl py-1 sm:py-3 sm:px-6 lg:px-8"
          : ""
      )}
    >
      <div className={clsx(type === "normal" ? "px-2" : "")}>
        <div
          style={
            type === "fillingScroll" || type === "fixedWitdhScroll"
              ? { minHeight: mainHeight }
              : type === "filling"
              ? { height: mainHeight }
              : {}
          }
          className={clsx(
            type !== "fillingScroll" &&
              type !== "fixedWitdhScroll" &&
              type !== "filling" &&
              "mx-auto max-w-2xl pt-6 pb-24 sm:px-6 sm:pt-12 sm:pb-32 lg:max-w-5xl lg:px-8 xl:max-w-6xl",
            type === "fixedWitdhScroll" && "mx-auto max-w-[110rem]",
            "bg-slate-50 dark:bg-gray-900"
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default MainLayout;
