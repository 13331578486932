import React, { useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import ExportedImage from "next-image-export-optimizer";
import { StarIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import Link from "next/link";
import { PlayCircleIcon } from "@heroicons/react/24/outline"; // Change to solid version
import { PlayCircleIcon as PlayCircleIconSolid } from "@heroicons/react/24/solid"; // Change to solid version

import LogoText from "@components/shared/LogoText";
import BackgroundPattern from "./BackgroundPattern";
import TypewriterEffect from "@components/shared/TypewriterEffect";
import VideoModal from "@components/shared/VideoModal";

export default function Hero() {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  return (
    <div
      className="relative isolate overflow-hidden bg-white dark:bg-gray-900"
      id="vorteile"
    >
      {/* Background pattern */}
      <BackgroundPattern />
      <div className="mx-auto mt-2 max-w-7xl px-6 pt-10 pb-20 sm:mt-10 sm:pb-24 lg:flex lg:px-8 xl:mt-12">
        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
          <div className="hidden sm:block">
            <LogoText />
          </div>
          <div className="mt-8 sm:mt-32 lg:mt-16">
            <Link
              href="/preise"
              className="focus-ring flex items-center gap-x-2 gap-y-2 rounded-full sm:inline-flex"
            >
              <span className="flex items-center rounded-full bg-gradient-to-br from-orange-500/10 to-red-400/10 px-3 py-1 text-sm font-semibold leading-6 ring-1 ring-inset ring-orange-500/20">
                <span className="text-premium">Aktuelles</span>
              </span>
              <span className="inline-flex items-center space-x-2 text-sm font-medium leading-5 text-slate-600 dark:text-gray-300 sm:leading-6">
                <span>
                  <span className="hidden sm:inline">Lerne</span> BGB AT
                  dauerhaft kostenlos
                </span>
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </div>
          <h1 className="mt-6 text-5xl font-bold tracking-tight text-slate-900 dark:text-white sm:mt-10 sm:text-[3.5rem]">
            Lerne Jura{" "}
            <span className="text-premium block sm:inline">
              <TypewriterEffect
                words={[
                  "kompakt.",
                  "verlinkt.",
                  "interaktiv.",
                  "einfach.",
                  "effizient.",
                  "modern.",
                ]}
              />
            </span>
          </h1>
          <p className="mt-6 text-lg leading-6 text-slate-600 dark:text-gray-300 sm:leading-8">
            Mehr Verständnis und bessere Noten mit interaktivem Jura-Training -
            kompakt, verlinkt und modular.
          </p>
          <div className="mt-10 flex flex-col items-start gap-y-2 gap-x-6 sm:flex-row sm:items-center">
            <Link
              href="/registrieren"
              id="cta-hero"
              aria-label="CTA in Hero section"
              className="focus-ring w-full rounded-md bg-orange-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-400 sm:w-auto"
            >
              Jetzt kostenlos starten
            </Link>
            <button
              onClick={() => setIsVideoModalOpen(true)}
              className=" focus-ring flex w-full items-center justify-center gap-x-2 rounded-md border border-slate-100 px-3.5 py-2.5 text-sm font-semibold text-slate-900 hover:bg-slate-200/20 dark:border-white/10 dark:text-white dark:hover:bg-slate-700/20 sm:w-auto"
            >
              <PlayCircleIcon className="h-5 w-5" />
              Demo-Video ansehen
            </button>
          </div>
          <div className="mt-6 flex flex-col gap-x-2 gap-y-2 sm:flex-row sm:items-center ">
            <div className="flex">
              {[1, 2, 3, 4, 5].map((rating) => (
                <StarIcon
                  key={rating}
                  className="h-5 w-5 flex-shrink-0 text-yellow-400"
                  aria-hidden="true"
                />
              ))}
            </div>
            <p className="ml-0.5 text-sm font-medium text-slate-600 dark:text-gray-300">
              <span className="font-bold">4.9 von 5 Sternen</span> aus über 60
              Google-Bewertungen
            </p>
          </div>
        </div>

        <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 md:-translate-x-20 lg:mt-10 lg:mr-0 lg:max-w-none lg:flex-none">
          <motion.div
            initial={{ opacity: 0, x: 100, scale: 0.8 }}
            whileInView={{ opacity: 1, x: 0, scale: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            className="relative max-w-2xl flex-none overflow-hidden rounded-lg sm:max-w-3xl md:max-w-5xl lg:max-w-none"
          >
            <button
              onClick={() => setIsVideoModalOpen(true)}
              className="focus-ring group absolute inset-0 flex items-center justify-center"
              aria-label="Play demo video"
            >
              <svg width="0" height="0">
                <defs>
                  <linearGradient
                    id="playButtonGradient"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="100%"
                  >
                    <stop offset="0%" stopColor="#f97316" />
                    <stop offset="100%" stopColor="#f87171" />
                  </linearGradient>
                </defs>
              </svg>
              <PlayCircleIconSolid
                className="h-20 w-20 transition-all group-hover:scale-125"
                style={{ fill: "url(#playButtonGradient)" }}
              />
            </button>
            <ExportedImage
              className="block dark:hidden"
              src="/images/mockup_laptop_phone_light.png"
              alt="Screenshot der Karteikarte Willenserklärung in Lern-App im Light-Mode"
              width={1024}
              height={1024}
              style={{ objectFit: "cover" }}
              priority
            />
            <ExportedImage
              className="hidden dark:block"
              src="/images/mockup_laptop_phone_dark.png"
              alt="Screenshot der Karteikarte Willenserklärung in Lern-App im Dark-Mode"
              width={1024}
              height={1024}
              style={{ objectFit: "cover" }}
              priority
            />
            {/* <img
              src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
              alt="App screenshot"
              width={2432}
              height={1442}
              className="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"
            /> */}
          </motion.div>
        </div>
      </div>
      <VideoModal
        isOpen={isVideoModalOpen}
        onClose={() => setIsVideoModalOpen(false)}
        embedId="60N8I-Lt5Yc"
      />
    </div>
  );
}
