import Link from "next/link";
import BackgroundPattern2 from "./BackgroundPattern2";
import RevealContainer from "./RevealContainer";
import TypewriterEffect from "@components/shared/TypewriterEffect";

export default function CTA() {
  return (
    <div className="relative isolate overflow-hidden bg-white dark:bg-gray-900">
      <BackgroundPattern2 />

      <RevealContainer className="py-24 px-6 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-slate-900 dark:text-white sm:text-5xl">
            Lerne{" "}
            <TypewriterEffect
              words={[
                "einfach",
                "interaktiv",
                "modular",
                "kostenlos",
                "plattformübergreifend",
              ]}
            />
            Jura.
          </h2>
          <p className="mx-auto mt-4 max-w-xl text-lg leading-8 text-slate-600 dark:text-gray-300 sm:mt-6">
            Starte jetzt kostenlos und überzeug dich selbst.
          </p>
          <div className="mt-6 flex items-center justify-center gap-x-6 sm:mt-10">
            <Link
              href="/registrieren"
              aria-label="CTA in CTA section"
              className="focus-ring rounded-md bg-orange-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400"
            >
              Jetzt kostenlos starten
            </Link>
            {/* <a
              href="#vorteile"
              className="text-sm font-semibold leading-6 text-slate-900 dark:text-white"
            >
              mehr erfahren <span aria-hidden="true">→</span>
            </a> */}
          </div>
        </div>
      </RevealContainer>
      {/* <svg
        viewBox="0 0 1024 1024"
        className="absolute top-0 left-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
        aria-hidden="true"
      >
        <circle
          cx={512}
          cy={512}
          r={512}
          fill="url(#8d958450-c69f-4251-94bc-4e091a323369)"
          fillOpacity="0.7"
        />
        <defs>
          <radialGradient id="8d958450-c69f-4251-94bc-4e091a323369">
            <stop stopColor="#f59e0b" />
            <stop offset={1} stopColor="#ffedd5" />
          </radialGradient>
        </defs>
      </svg> */}
    </div>
  );
}
