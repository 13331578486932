import ExportedImage from "next-image-export-optimizer";
import Link from "next/link";

export default function ExtensionSection() {
  return (
    <div className="bg-white dark:bg-gray-900">
      <div className="mx-auto max-w-7xl py-10 sm:px-6 sm:py-12 lg:px-8">
        <div className="relative isolate overflow-hidden px-6 pt-16  shadow-2xl ring-1 ring-white/10 dark:bg-gray-900 sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            aria-hidden="true"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
          >
            <circle
              r={512}
              cx={512}
              cy={512}
              fill="url(#orange-gradient)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient id="orange-gradient">
                <stop stopColor="#FFA500" />
                <stop offset={1} stopColor="#FF4500" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-16 lg:text-left">
            <h2 className="text-base font-semibold leading-7 text-orange-500">
              Ab sofort kostenlos verfügbar
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-slate-900 dark:text-white sm:text-3xl">
              <span className="text-premium">JuraSense</span> Chrome Erweiterung
            </p>
            <p className="mt-4 text-lg leading-6 text-slate-600 dark:text-gray-300 sm:leading-7">
              Hol dir unsere kostenlose Chrome-Erweiterung und lerne ganz
              nebenbei auf jeder Webseite mit interaktiven Jurahilfe.de-Inhalten
              zu automatisch erkannten Rechtsbegriffen.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              <Link
                href="https://chromewebstore.google.com/detail/jurasense-by-jurahilfede/aibihpiabfankhoaiioigglajaplnhib?authuser=0&hl=de"
                aria-label="Chrome Web Store"
                className="rounded-md border border-gray-100 bg-white px-3.5 py-0.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white dark:border-transparent"
              >
                <ExportedImage
                  src="/images/chrome.png"
                  className="rounded"
                  alt="Chrome Extension QR Code"
                  width={180}
                  height={180}
                />
              </Link>
            </div>
          </div>
          {/* TODO: Add carousel with different screenshots for dark and light mode */}
          <div className="relative mt-10 h-80 md:mt-16 lg:mt-8">
            <ExportedImage
              alt="App screenshot"
              src="/images/extension_1.png"
              width={1824}
              height={1080}
              className="absolute left-0 top-0 w-full max-w-none rounded-md md:w-[35rem]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
