import React, { useState, useEffect } from "react";

const TypewriterEffect = ({ words }: { words: string[] }) => {
  const [currentWord, setCurrentWord] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const typingInterval = setInterval(() => {
      if (!isDeleting && currentWord === words[currentIndex]) {
        setTimeout(() => setIsDeleting(true), 1000);
        return;
      }

      if (isDeleting && currentWord === "") {
        setIsDeleting(false);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
        return;
      }

      setCurrentWord((prev) =>
        isDeleting
          ? prev.slice(0, -1)
          : words[currentIndex].slice(0, prev.length + 1)
      );
    }, 100);

    return () => clearInterval(typingInterval);
  }, [currentWord, currentIndex, isDeleting]);

  return (
    <span className="text-premium">
      {currentWord}
      <span className="animate-blink text-premium -mt-1 ml-[1px] font-semibold">
        |
      </span>
    </span>
  );
};

export default TypewriterEffect;
