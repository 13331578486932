import MainLayout from "@components/layout/MainLayout";
import Hero from "./Hero";
import Features1 from "./Features1";
import Features2 from "./Features2";
import FAQ, { FAQItem } from "./FAQ";
import Footer from "@components/landingPage/Footer";
import CTA from "./CTA";
import Features3 from "./Features3";
import VideoSection from "./VideoSection";
import ExtensionSection from "./ExtensionSection";

const faqs: FAQItem[] = [
  {
    question: "Wie funktioniert Jurahilfe.de?",
    answer:
      "Jurahilfe.de ist eine Online-Lernplattform, die auf eine moderne Prüfungsvorbereitung für Jurastudierende vom ersten Semester bis zu den Examina spezialisiert ist. Wir bieten kompakte und modulare Lerninhalte, interaktive Tests, einen intuitiven Fortschritts-Tracker, mobilen Zugriff und persönlichen 1:1-Unterricht an, um effizientes und erfolgreiches Lernen zu ermöglichen.",
  },
  {
    question: "Für welchen Anwendungsfall kann ich Jurahilfe.de nutzen?",
    answer:
      "Du kannst Jurahilfe.de perfekt als Einstiegshilfe in ein neues Rechtsgebiet nutzen, als schnelle und effektive Wiederholungsmöglichkeit vor einer Klausur und als langfristige Unterstützung bei der Prüfungsvorbereitung in Vorbereitung auf die Anfänger- und Fortgeschrittenenübungen sowie das erste und zweite juristische Staatsexamen. Auch beim Erstellen von Hausarbeiten bietet dir Jurahilfe.de einen schnellen Überblick über Themen und Probleme deines Sachverhalts und hilft dir für die Literaturrecherche, Probleme zu verschlagworten und liefert dir die Prüfungsschemata, um deine Lösungsskizze zusammen zu bauen. Individuelle Verständnisprobleme kannst du durch individuellen Einzelunterricht bei einem professionellen Repetitor beheben.",
  },
  {
    question: "Was bedeutet mehrstufiges Lernsystem?",
    answer: `Jurahilfe.de bietet ein mehrstufiges Lernkonzept, das aus vier Stufen besteht: ”Verstehen”, “Wiederholen”, “Falltraining & Abschlusstest” und “Vertiefen”.\nIn Stufe 1 geht es um das Verstehen. Lies dir die Inhalte durch und versuche sie gedanklich zu durchdringen. Anstatt eines langen Textes, findest du hier kompakt und prägnant gehaltene Inhalte, aufgeteilt in einzelne Lernelemente.\nStufe 2 umfasst das Wiederholen. Wiederhole die Inhalte auf Karteikarten in Form von Fragen und Antworten. So kannst du dir sicher sein, dass du die Inhalte wirklich verstanden hast.\nStufe 3 beinhaltet ein Falltraining mit Abschlusstest. In benoteten Multiple-Choice-Aufgaben werden Grundwissen und kleine Fälle abgefragt, um eine klausurnahe Perspektive auf das theoretische  Wissen zu gewinnen und zu verstehen, wie gut du das Rechtsgebiet bereits beherrschst. \nStufe 4 dient dem Vertiefen. Hier findest du Empfehlungen zu den nächsten Schritten und kannst individuellen Einzelunterricht bei einem professionellen Repetitor zu buchen, um dein Wissen zu vertiefen, Verständnislücken zu schließen und dein Können im Umgang mit Klausuren zu verbessern.`,
  },
  {
    question:
      "Kann ich mich allein mit Jurahilfe.de auf meine Klausur und das Examen vorbereiten?",
    answer:
      "Auf  Jurahilfe.de sind alle relevanten Inhalte, die beherrscht werden müssen, in kompakter Form zusammengefasst (Beta-Version: lediglich Zivilrecht ohne Nebengebiete). Die Inhalte werden präzise und leicht verständlich erklärt. Sollten weiterführende Informationen zur Vertiefung erforderlich sein, kann zunächst auf externe Materialien zurückgegriffen werden. Auf lange Sicht planen wir aber die Integration von Langtexten, Erklärungsvideos und einem modernen Klausurenkurs in unsere Plattform, für ein komplett autarkes Lernerlebnis. Zusätzlich hast du die Möglichkeit, auf Stufe 4 individuellen Einzelunterricht bei einem professionellen Repetitor zu buchen, um dein Verständnis zu vertiefen, eventuelle Verständnislücken zu schließen und dein Können im Umgang mit Klausuren zu verbessern.",
  },
  // {
  //   question: "Was bedeutet Beta-Phase / Beta-Version?",
  //   answer:
  //     "Die Beta-Phase ist eine Testphase, in der das Produkt von einer begrenzten Gruppe von Nutzern getestet wird, bevor es offiziell veröffentlicht wird. In dieser Phase ist Jurahilfe.de komplett kostenlos, es sind noch nicht alle Funktionen und Inhalte voll verfügbar und wir sammeln Feedback von den Nutzern, um mögliche Fehler zu beheben und das Produkt zu verbessern.",
  // },
  {
    question: "Sind jetzt schon alle Inhalte verfügbar?",
    answer:
      "Zurzeit befinden wir uns noch im Aufbau der Inhaltsdatenbank. Aktuell bieten wir Inhalte zu großen Teilen des Zivilrechts und Strafrecht. Öffentliches Recht und Nebengebiete werden bald folgen. In Planung sind auch Videos, die die kompakten Inhalte um ausführliche Erklärungen ergänzen.",
  },
  {
    question: "Wie kann ich Feedback geben?",
    answer: `Feedback kann über unser Feedback-Formular gegeben werden, das über das Menü in der App erreichbar ist, oder jederzeit per Live-Chat oder E-Mail an info@jurahilfe.de. Auch zu unseren Lerinhalten kannst du über einen "Feedback"-Button Feedback geben (rechts unten im Lernelement).`,
  },
  {
    question: "Wie viel kostet die Nutzung von Jurahilfe.de?",
    answer:
      "Mit Jurahilfe Free lernst du dauerhaft kostenlos BGB AT. Alle anderen Inhalte und Funktionen lassen sich mit verschiedenen Jurahilfe Pro plänen flexibel und monatlich kündbar freischalten. Die Preise findest du auf unserer Preisseite.",
  },
  {
    question: "Kann ich Jurahilfe.de auch mobil nutzen?",
    answer:
      "Ja, unsere Web-App ist für mobile Geräte optimiert und kann auf Smartphones und Tablets genutzt werden. Du hast so jederzeit und überall Zugang zu unseren Lerninhalten und Funktionen und kannst in Echtzeit nahtlos vom PC zum Smartphone oder Tablet wechseln und wieder zurück.",
  },

  {
    question: "Wie funktioniert das Lernen mit interaktiven Tests?",
    answer:
      "Unsere interaktiven Tests sind eine tolle Möglichkeit, dein Wissen zu überprüfen und zu festigen. Du erhältst unmittelbares Feedback zu deinen Antworten und kannst so sehen, welche Bereiche noch Verbesserungspotential haben. Wir bieten dir sowohl ein intelligentes digitales Karteikartensystem als auch benotete Multiple-Choice-Tests, um verschiedene Lernmethoden abzudecken. Zukünftig sind unter anderem weitere Modi für das Abfragen im Karteikartensystem (z.B. Lückentexte), aber auch ein innovativer Klausurenkurs geplant.",
  },
  {
    question: "Was passiert, wenn ich mit dem Service nicht zufrieden bin?",
    answer:
      "Wir sind fortlaufend bestrebt, dir den bestmöglichen Service zu bieten und dich in deiner juristischen Ausbildung zu unterstützen. Wenn du jedoch nicht zufrieden bist, kontaktiere uns bitte und teile uns dein Anliegen mit. Wir werden unser Bestes tun, um das Problem zu lösen.",
  },
];

function Homepage() {
  return (
    <MainLayout type="filling">
      <Hero />
      <Features1 />
      <Features2 />
      <VideoSection
        title="Warum verlinktes Lernen?"
        subtitle="Echtes Systemverständnis durch Verlinkungen"
        text="Tausende Verlinkungen überall wo es Verbindungen gibt, bringen dich von Inselwissen zu Systemverständnis. Klick auf einen Rechtsbegriff und lies die Definition oder schau dir nochmal alles dazu im Kontext an. Bequem, einfach und ungeheuer wertvoll."
        youtubeId="dWbCbB-1xro"
      />
      <Features3 />
      <ExtensionSection />
      {/* <Numbers /> */}
      <VideoSection
        subtitle="Klausur, Hausarbeit und Examen"
        title="Wofür brauche ich Jurahilfe.de?"
        youtubeId="h_V1Op7WO9Q"
      />
      <CTA />
      <FAQ faqs={faqs} />
      <CTA />
      <Footer />
    </MainLayout>
  );
}

export default Homepage;
